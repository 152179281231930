import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';
import { useStaticQuery, graphql } from 'gatsby';
import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import CookieConsent from 'react-cookie-consent';

// Sections
import PromoWithForm from '../containers/Landing/sections/PromoWithForm';
import IntroWithFeatures from '../containers/Landing/sections/IntroWithFeatures';
import BenefitsWithVideo from '../containers/Landing/sections/BenefitsWithVideo';
import SignUpCallout from '../containers/Landing/sections/SignUpCallout';
import SetupSteps from '../containers/Landing/sections/SetupSteps';
import StaticFooter from '../containers/Landing/sections/StaticFooter';

// Styles
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Landing/landing.style';

// Components
import SEO from '../components/seo';

// Smooth Scroll
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

export default () => {
  const DataQuery = useStaticQuery(graphql`
    query {
      eComm360ShopifyV2Json {
        A {
          SEO {
            seoTitle
            seoDesc
          }
          PromoWithForm {
            promoLogo {
              publicURL
            }
            promoHeading
            promoText
            promoImage {
              publicURL
            }
            formHeading
            formLogo {
              publicURL
            }
            formPageType
            formLink
            formButton
          }
          IntroWithFeatures {
            introHeading
            introText1
            introText2
            dataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          BenefitsWithVideo {
            bwvHeading
            bwvDataBenefits {
              content
            }
          }
          SignUpCallout {
            signUpHeading
            signUpText
            signUpButton
          }
          SetupSteps {
            setupHeading
            setupText
          }
          StaticFooter {
            footerAbout
            dataAwards {
              content
            }
          }
        }
      }
    }
  `);

  // Data
  const Data = DataQuery.eComm360ShopifyV2Json.A;

  // SEO
  const seoTitle = Data.SEO.seoTitle;
  const seoDesc = Data.SEO.seoDesc;

  // PromoWithForm
  const promoLogo = Data.PromoWithForm.promoLogo.publicURL;
  const promoHeading = Data.PromoWithForm.promoHeading;
  const promoText = Data.PromoWithForm.promoText;
  const promoImage = Data.PromoWithForm.promoImage.publicURL;
  const formHeading = Data.PromoWithForm.formHeading;
  const formLogo = Data.PromoWithForm.formLogo.publicURL;
  const formPageType = Data.PromoWithForm.formPageType;
  const formLink = Data.PromoWithForm.formLink;
  const formButton = Data.PromoWithForm.formButton;

  // IntroWithFeatures
  const introHeading = Data.IntroWithFeatures.introHeading;
  const introText1 = Data.IntroWithFeatures.introText1;
  const introText2 = Data.IntroWithFeatures.introText2;
  const dataFeatures = Data.IntroWithFeatures.dataFeatures;

  // BenefitsWithVideo
  const bwvHeading = Data.BenefitsWithVideo.bwvHeading;
  const bwvDataBenefits = Data.BenefitsWithVideo.bwvDataBenefits;

  // SignUpCallout
  const signUpHeading = Data.SignUpCallout.signUpHeading;
  const signUpText = Data.SignUpCallout.signUpText;
  const signUpButton = Data.SignUpCallout.signUpButton;

  // SetupSteps
  const setupHeading = Data.SetupSteps.setupHeading;
  const setupText = Data.SetupSteps.setupText;

  // StaticFooter
  const footerAbout = Data.StaticFooter.footerAbout;
  const dataAwards = Data.StaticFooter.dataAwards;

  return (
    <ThemeProvider theme={landingTheme}>
      <Fragment>
        <SEO title={seoTitle} description={seoDesc} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <PromoWithForm
            promoLogo={promoLogo}
            promoHeading={promoHeading}
            promoText={promoText}
            promoImage={promoImage}
            formHeading={formHeading}
            formLogo={formLogo}
            formPageType={formPageType}
            formLink={formLink}
            formButton={formButton}
          />
          <IntroWithFeatures
            introHeading={introHeading}
            introText1={introText1}
            introText2={introText2}
            dataFeatures={dataFeatures}
          />
          <BenefitsWithVideo
            bwvHeading={bwvHeading}
            bwvDataBenefits={bwvDataBenefits}
          />
          <SignUpCallout
            signUpHeading={signUpHeading}
            signUpText={signUpText}
            signUpButton={signUpButton}
          />
          <SetupSteps setupHeading={setupHeading} setupText={setupText} />
          <StaticFooter footerAbout={footerAbout} dataAwards={dataAwards} />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            style={{ background: '#2b3d4d' }}
            buttonStyle={{
              color: '#2b3d4d',
              background: '#e6ff0f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            cookieName="gatsby-plugin-gdpr-cookies"
          >
            We are using cookies to give you the best experience on our website.
            <br />
            You can find out more about which cookies we are using or switch
            them off in settings. To find out more, see our
            <a
              href="https://cybertonica.com/privacy-policy?ref=discover"
              className="DefaultPrivacyLink"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </CookieConsent>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
